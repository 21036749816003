import { Route, Switch } from 'react-router-dom'

import { Header } from '../components/Header'

import { Home } from '../layout/Home'
import { EventBooking } from '../layout/EventBooking'
import { ConsultantBooking } from '../layout/ConsultantBooking'
import { Events } from '../layout/Events'
import { DeleteBookingEvent } from "../layout/DeleteBookingEvent";
import { SingleBookingEvent } from "../layout/SingleBookingEvent"
import { SingleBookingConsultant } from '../layout/SingleBookingConsultant'
import { DeleteBookingConsultant } from '../layout/DeleteBookingConsultant'


const routes = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Header />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/evento" component={EventBooking} />
        <Route exact path="/evento/:id" component={EventBooking} />
        <Route exact path="/reservar-por-consultor" component={ConsultantBooking} />
        <Route exact path="/eventos" component={Events} />
        <Route
          exact
          path="/single-booking-event/:id"
          component={SingleBookingEvent}
        />
        <Route
          exact
          path="/confirm-delete-booking-event"
          component={DeleteBookingEvent}
        />
        <Route
          exact
          path="/single-booking-consultant/:id"
          component={SingleBookingConsultant}
        />
        <Route
          exact
          path="/confirm-delete-booking-consultant"
          component={DeleteBookingConsultant}
        />

      </Switch>
    </div>

  )
}

export default routes;
