import { GET_ALL_EVENTS, GET_SINGLE_EVENT, RESET_EVENT, SEND_DATA_BOOKING, RESPONSE_DELETE_BOOKING_EVENT, GET_BOOKINGS_EVENTS } from '../../../constants'

const INITIAL_STATE = {
  didInvalidate: false,
  isFetching: false,
  featured: null,
  events: null,
  singleEvent: null,
  bookingsEvent: null,
  shortTimeSpan: null,
  deleteBookingEvent: null
}

const eventReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case `${RESET_EVENT}`: {
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        featured: null,
        events: null,
        singleEvent: null,
        bookingsEvent: null,
        shortTimeSpan: null
      }
    }
    case `${GET_SINGLE_EVENT}_FULFILLED`: {
      if (payload.shortTimeSpan) {
        return {
          ...state,
          didInvalidate: false,
          isFetching: false,
          singleEvent: payload.event,
          bookingsEvent: payload.bookingsEvent,
          shortTimeSpan: payload.shortTimeSpan
        }
      } else {
        return {
          ...state,
          didInvalidate: false,
          isFetching: false,
          singleEvent: payload.event,
          bookingsEvent: payload.bookingsEvent
        }
      }
    }
    case `${GET_SINGLE_EVENT}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      }
    }
    case `${GET_SINGLE_EVENT}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    }
    case `${GET_ALL_EVENTS}_FULFILLED`: {
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        featured: payload.featured,
        events: payload.events
      }
    }
    case `${GET_ALL_EVENTS}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      }
    }
    case `${GET_ALL_EVENTS}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    }
    case `${SEND_DATA_BOOKING}_FULFILLED`: {
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
      }
    }
    case `${SEND_DATA_BOOKING}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      }
    }
    case `${SEND_DATA_BOOKING}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    }
    case `${RESPONSE_DELETE_BOOKING_EVENT}_FULFILLED`: {
      return {
        ...state,
        deleteBookingEvent: payload.deleteBookingEvent,
        didInvalidate: false,
        isFetching: false,
      };
    }
    case `${RESPONSE_DELETE_BOOKING_EVENT}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      };
    }
    case `${RESPONSE_DELETE_BOOKING_EVENT}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }
    default:
      return state;
  }
};

export { eventReducer }