import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllStores, getWorkingDays, getRestingDays, sendBookingConsultant, getDaysEventsStore, emailDeleteBookingConsultant, deleteBookingConsultant, getBookingsConsultants } from '../../redux/actions'

const useConsultant = () => {

  const { stores, featured, zones, workingDays, restingDays, isFetching, bookingConsultant, dataCalendar, daysEventStore } = useSelector(
    (state) => state.consultantReducer,
  )
  const dispatch = useDispatch()


  useEffect(() => {
    getStores()
  }, [])


  const getStores = useCallback(async () => {
    // if(!events){
    dispatch(getAllStores())
    // }
  }, [dispatch])

  const getWorkDays = useCallback(async (id) => {
    // if(!events){
    dispatch(getWorkingDays(id))
    // }
  }, [dispatch])

  const getRestDays = useCallback(async (id) => {
    // if(!events){
    dispatch(getRestingDays(id))
    // }
  }, [dispatch])

  const getBookingsConsultantsId = useCallback(async (id) => {
    // if(!events){
    return dispatch(getBookingsConsultants(id))
    // }
  }, [dispatch])

  const getDaysEvent = useCallback(async (id) => {
    // if(!events){
    dispatch(getDaysEventsStore(id))
    // }
  }, [dispatch])

  const sendDataBooking = useCallback(async (data) => {
    // if(!events){
    dispatch(sendBookingConsultant(data))
    // }
  }, [dispatch])

  const deleteBookingConsultantEmail = useCallback(
    async (id, data) => {
      // if(!events){
      dispatch(emailDeleteBookingConsultant(id, data));
      // }
    }, [dispatch]);

  const sendDeleteBooking = useCallback(
    async (id) => {
      // if(!events){
      dispatch(deleteBookingConsultant(id));
      // }
    }, [dispatch]);


  return { getStores, stores, featured, zones, getWorkDays, getRestDays, workingDays, restingDays, isFetching, bookingConsultant, sendDataBooking, dataCalendar, getDaysEvent, daysEventStore, deleteBookingConsultantEmail, sendDeleteBooking, getBookingsConsultantsId }
}

export { useConsultant }