import { Link } from "react-router-dom";
import {
  FiCard,
  FiCardActionArea,
  FiCardContent,
  FiCardMedia,
} from "./FullImageCard";
import makeStyles from "@material-ui/core/styles/makeStyles";
import './styles.css'

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  /**
   * Max Card with for demo
   * same values used in Material-Ui Card Demos
   */
  card: {
    //maxWidth: 345
    margin: '5px',
    boxShadow: '0px 2px 1px -1px rgb(255, 255, 255),0px 1px 1px 0px rgb(255, 255, 255),0px 1px 3px 0px rgb(255, 255, 255)',
  },

  /**
   * Applied to Orginal Card demo
   * Same vale used in Material-ui Card Demos
   */
  media: {
    height: 140,
  },

  /**
   * Demo stlying to inclrease text visibility
   * May verry on implementation
   */
  fiCardContent: {
    color: "#ffffff",
    backgroundColor: "rgba(0,0,0,0)",
    width: "30vw",
    height: "20vh",
    textAlign: "center",


    /*"&:hover": {
      background: "rgba(0,0,0,0.24)"
    }*/
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)",
  },
  containerTitle: {
    position: "relative",
    top: "40%",
    fontSize: 25,
    fontWeight: 600,
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  img: {
    backgroundSize: "contain",
  },
});

const CardButtonNav = ({ title, srcImage, linkNav, onClick }) => {
  const classes = useStyles();
  return (
    <FiCard className={classes.card} onClick={onClick}>
      <Link className={classes.link} to={linkNav}>
        <FiCardActionArea>
          <FiCardMedia
            className={classes.img}
            media="picture"
            alt="Contemplative Reptile"
            image={srcImage}
            //image="https://www.online-911.com/wp-content/uploads/2016/10/juleriaqueunicenter.jpg"
            title="Contemplative Reptile"
          />
          <FiCardContent className={`fiCardContentR ${classes.fiCardContent}`}>
            <div className={`containerTitle ${classes.containerTitle}`}>{title}</div>
          </FiCardContent>
        </FiCardActionArea>
      </Link>
    </FiCard>
  );
};

export { CardButtonNav };
