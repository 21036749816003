import React, { useState } from "react";
import { Header } from "../../components/Header";
import { Slides } from "../../components/Slides";
import { CardButtonNav } from "../../components/CardButtonNav";
import { useEvents } from "../../hooks";
import { EventCards } from "../../components/EventCards";
import "./styles.css";
import { Button } from "@material-ui/core";
import eventImage from "../../assets/banner-otros-eventos.jpg";
import consultImage from "../../assets/banner-reserva-cita.jpg";

const Home = () => {
  const { events, featured, bookingsEvent } = useEvents();
  const [ButtonEvent, setButtonEvent] = useState(true);

  const handleShowEvents = () => {
    setButtonEvent(!ButtonEvent);
  };

  return (
    <div className="homeDiv">
      <Header isHome={true} />
      {featured && <Slides featured={featured} />}
      {!ButtonEvent && <EventCards events={events} />}
      {ButtonEvent && (
        <div className="container-btn">
          <CardButtonNav
            className="cardButtonNav"
            sx={{ boxShadow: 0 }}
            linkNav="/eventos"
            title=""
            srcImage={eventImage}
          />
          <CardButtonNav
            linkNav="/reservar-por-consultor"
            title=""
            srcImage={consultImage}
          />
        </div>
      )}
    </div>
  );
};

export { Home };

/* history.push(`/private/singleBingo/${rowData._id}`
 <Route exact path="/private/singleBingo/:id" component={SingleBingo} />
 
 { match, history }
 useEffect(() => {
    dispatch(singleBingo(match.params.id));
  }, [dispatch, match]);
  */
