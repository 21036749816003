import React, { useEffect, useState } from "react";
// import { useConsultant } from "../../hooks";
import header_img from "../../assets/Header/logo.png";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { useParams } from "react-router-dom";
import { useConsultant } from "../../hooks/useConsultant";

import { CircularProgress, Button } from "@material-ui/core";
import { useEvents } from "../../hooks/useEvents";

const SingleBookingConsultant = () => {
  const [datos, setDatos] = useState({});
  const [loading, setLoading] = useState(true);
  const [individualBooking, setIndividualBooking] = useState();
  const {
    deleteBookingConsultantEmail,
    deleteBookingConsultant,
    sendDeleteBooking,
    isFetching,
    stores,
    zones,
    getBookingsConsultantsId,
  } = useConsultant();
  const { featured } = useEvents();

  const { id } = useParams();
  console.log("id", id);

  let history = useHistory();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    sendDeleteBooking(id);
    deleteBookingConsultantEmail(id, individualBooking);
  };

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    console.log("iddddddd", id);
    const data = await getBookingsConsultantsId(id);
    /*const eventName = events?.find((event) => {
      return event.id === data?.value.bookingsEvent.eventId;
    });*/
    console.log("data", data);
    //data.value.bookingsEvent.eventName = eventName?.name;
    setIndividualBooking(data.value.bookingsConsultant);
    setDatos(data.value.bookingsConsultant);
    setLoading(false);
  };

  console.log("individualBooking", individualBooking);

  return (
    <div>
      {loading ? (
        <div>cargando...</div>
      ) : (
        <div>
          <div className="react-container">
            <div className="containerTittle">
              <div className="tittle">TU TICKET DE RESERVA: </div>
            </div>
            <div className="cart-ticket">
              <div>
                <div className="ticket-header">
                  <img
                    className="img_header"
                    src={header_img}
                    alt="Juleriaque logo"
                  />
                </div>
                <div className="ticket-container">
                  <div className="ticket-left">
                    <div className="ticket-line">
                      <b>Nombre y apellido: </b> {datos?.name} {datos?.lastname}
                    </div>
                    <div className="ticket-line">
                      <b>Email:</b> {datos?.email}
                    </div>
                  </div>
                  <div className="ticket-right">
                    {/*<div className="ticket-line">
                  <b>Consultor:</b> }
                </div>*/}
                    <div className="ticket-line">
                      <b>Fecha:</b> {new Date(datos?.day).toLocaleDateString()}
                    </div>
                    <div className="ticket-line">
                      <b>Hora:</b> {datos?.time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="order input-container">
            <div className="button-spacex">
              <Button
                className="buttonForma"
                type="submit"
                variant="contained"
                // onClick={onClick}
                disabled={isFetching}
              >
                {isFetching && <CircularProgress size={14} />}
                {!isFetching && "CANCELAR RESERVA"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export { SingleBookingConsultant };
