import React, { useState } from "react";
// import { useConsultant } from "../../hooks";
import header_img from "../../assets/Header/logo.png";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { useParams } from "react-router-dom";
import { useEvents } from "../../hooks/useEvents";

import { CircularProgress, Button } from "@material-ui/core";
import { useEffect } from "react";

const SingleBookingEvent = () => {
  const [datos, setDatos] = useState({});
  const [loading, setLoading] = useState(true);
  const [individualBooking, setIndividualBooking] = useState();
  const {
    deleteBookingEventEmail,
    deleteBookingEvent,
    sendDeleteBooking,
    featured,
    events,
    isFetching,
    bookingsEvent,
    getBookingsEventsId,
  } = useEvents();
  //console.log("deleteBookingEvent111", bookingsEvent);

  // useEffect(() => {
  //   if(featured) console.log(featured.bookingIdQuery))
  // }, [stores]);

  const { id } = useParams();

  let history = useHistory();
  //let individualBooking = [];
  const handleSubmit = (ev) => {
    ev.preventDefault();
    sendDeleteBooking(id);
    //console.log("test", id, individualBooking);
    deleteBookingEventEmail(id, individualBooking);
  };

  useEffect(() => {
    getData();
    //console.log("events", events);
    /*individualBooking = bookingsEvent?.find((booking) => {
      return booking.idBokkingEvent === id;
    });*/
  }, [bookingsEvent]);

  const getData = async () => {
    const data = await getBookingsEventsId(id);
    const eventName = events?.find((event) => {
      return event.id === data?.value.bookingsEvent.eventId;
    });
    //console.log("eventName", eventName?.name);
    data.value.bookingsEvent.eventName = eventName?.name;
    setIndividualBooking(data.value.bookingsEvent);
    setDatos(data.value.bookingsEvent);
    setLoading(false);
  };

  /*const eventName = events?.find((event) => {
    return event.id === datos?.eventId;
  });*/

  return (
    <div>
      {loading ? (
        <div>cargando...</div>
      ) : (
        <div>
          <div className="react-container">
            <div className="containerTittle">
              <div className="tittle">TU TICKET DE RESERVA: </div>
            </div>
            <div className="cart-ticket">
              <div>
                <div className="ticket-header">
                  <img
                    className="img_header"
                    src={header_img}
                    alt="Juleriaque logo"
                  />
                </div>
                <div className="ticket-container">
                  <div className="ticket-left">
                    <div className="ticket-line">
                      <b>Nombre y apellido: </b> {datos?.name} {datos?.lastname}
                    </div>
                    <div className="ticket-line">
                      <b>Email:</b> {datos?.email}
                    </div>
                  </div>
                  <div className="ticket-right">
                    <div className="ticket-line">
                      <b>Evento:</b> {datos?.eventName}
                    </div>
                    <div className="ticket-line">
                      <b>Fecha:</b> {new Date(datos?.day).toLocaleDateString()}
                    </div>
                    <div className="ticket-line">
                      <b>Hora:</b> {datos?.time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="order input-container">
            <div className="button-spacex">
              <Button
                className="buttonForma"
                type="submit"
                variant="contained"
                // onClick={onClick}
                disabled={isFetching}
              >
                {isFetching && <CircularProgress size={14} />}
                {!isFetching && "CANCELAR RESERVA"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export { SingleBookingEvent };
