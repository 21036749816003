import React from "react";
import header_img from "../../assets/Header/logo.png";
import { useHistory } from "react-router-dom";

import "./styless.css";

import { Button } from "@material-ui/core";

const DeleteBookingEvent = () => {
  let history = useHistory();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    history.push(`/`);
  };

  return (
    <div>
      <div className="react-container">
        <div className="containerTittle">
          <div className="tittle">TU RESERVA FUE CANCELADA</div>
        </div>
        <div className="cart-ticket">
          <div>
            <div className="ticket-header-d">
              <img
                className="img_header"
                src={header_img}
                alt="Juleriaque logo"
              />
            </div>
            <div className="ticket-line-d">
              <div className="ticket-line-d">
                <b>
                  Tu pedido de cancelación de reserva fue recibido con éxito.
                </b>
              </div>
              <div className="ticket-line-d">
                <p>
                  Por favor verificá tu correo, en breve te llegará una
                  notificación.
                </p>
              </div>
              <div className="ticket-line-d">
                <b>
                  Muchas gracias por elegirnos, esperamos tenerte pronto de
                  vuelta.
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="order input-container">
        <div className="button-space">
          <Button
            className="buttonForm"
            type="submit"
            variant="contained"
            // onClick={onClick}
          >
            {"VOLVER AL INICIO"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export { DeleteBookingEvent };
