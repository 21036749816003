import React, { useState, useEffect } from "react";
import { Header } from "../../components/Header";
import { Slides } from "../../components/Slides";
import Select from "react-select";
import { fade, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";

import { useEvents } from "../../hooks";
import { EventCards } from "../../components/EventCards";
import { GoBackFabBtn } from "../../components/GoBackButton";
import "./styles.css";
import { lightGreen } from "@material-ui/core/colors";

import noEvents from "../../assets/banner-nohayeventos.jpg";
import noEventsM from "../../assets/banner-nohayeventosm.jpg";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#eaeaf0",

    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "50vh",
    height: "5.7vh",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const Events = () => {
  const { events, featured, shortTimeSpan } = useEvents();
  const [ButtonEvent, setButtonEvent] = useState(true);
  const handleShowEvents = () => {
    setButtonEvent(!ButtonEvent);
  };

  const now = moment().format("YYYY/MM/DD");
  const [filteredList, setFilteredList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [filterAux, setFilterAux] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [eventsEnabled, setEventsEnabled] = useState([]);
  const [brands, setBrands] = useState([{ value: "", label: "MARCAS" }]);
  const [location, setLocation] = useState([]);
  const [locations, setLocations] = useState([
    //{ value: "", label: "LOCALIDADES" },
    { value: "", label: "TODAS LAS LOCALIDADES" },
    { value: "CABA", label: "CABA" },
    { value: "GRAN BUENOS AIRES", label: "GRAN BUENOS AIRES" },
    { value: "MAR DEL PLATA", label: "MAR DEL PLATA" },
    { value: "ROSARIO", label: "ROSARIO" },
    { value: "CÓRDOBA", label: "CÓRDOBA" },
    { value: "MENDOZA", label: "MENDOZA" },
    { value: "NEUQUÉN", label: "NEUQUÉN" },
    { value: "SALTA", label: "SALTA" },
    { value: "TUCUMÁN", label: "TUCUMÁN" },
  ]);
  const [categories, setCategories] = useState([
    //{ value: "", label: "CATEGORIAS" },
    { value: "", label: "TODAS LAS CATEGORIAS" },
    { value: "fmasculino", label: "FRAGANCIA MASCULINA" },
    { value: "ffemenina", label: "FRAGANCIA FEMENINA" },
    { value: "Maquillaje", label: "MAQUILLAJE" },
    { value: "Tratamientos", label: "TRATAMIENTOS" },
  ]);
  let filterPressed = false;

  const handleBrandChange = (event) => {
    setSelectedBrand(event.value);
    /* if (event.value === "") {
      setFilteredList(eventsEnabled);
      setSelectedBrand("");
      setSelectedLocation("");
      setSelectedCategory("");
    } else {
      let filterBrands;
      let brandSelected = brands.find(
        (b) => b.value === parseInt(event.value, 10)
      );
      if (selectedCategory === "" && selectedLocation === "") {
        filterBrands = eventsEnabled.filter((ev) =>
          ev.brands.find((e) => e.id === brandSelected.value)
        );
      } else {
        filterBrands = eventsEnabled.filter((ev) =>
          ev.brands.find((e) => e.id === brandSelected.value)
        );
        if (selectedCategory !== "") {
          filterBrands = filterBrands.filter(
            (ev) => ev.type === selectedCategory
          );
        }
        if (selectedLocation !== "") {
          filterBrands = filterBrands.filter((ev) =>
            ev.stores.find((e) => e.city.toUpperCase() === selectedLocation)
          );
        }
      }
      setFilteredList(filterBrands);
    }*/
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.value);
    /*if (event.value === "") {
      setFilteredList(eventsEnabled);
      setSelectedBrand("");
      setSelectedLocation("");
      setSelectedCategory("");
    } else {
      let filterCategory;
      if (selectedBrand === "" && selectedLocation === "") {
        filterCategory = eventsEnabled.filter((ev) => ev.type === event.value);
      } else {
        filterCategory = eventsEnabled.filter((ev) => ev.type === event.value);
        if (selectedBrand !== "") {
          filterCategory = filterCategory.filter((ev) =>
            ev.brands.find((e) => e.id === selectedBrand.value)
          );
        }
        if (selectedLocation !== "") {
          filterCategory = filterCategory.filter((ev) =>
            ev.stores.find((e) => e.city.toUpperCase() === selectedLocation)
          );
        }
      }
      setFilteredList(filterCategory);
    }*/
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.value);
    /* if (event.value === "") {
      setFilteredList(eventsEnabled);
      setSelectedBrand("");
      setSelectedLocation("");
      setSelectedCategory("");
    } else {
      let filterLocation;
      if (selectedBrand === "" && selectedCategory === "") {
        filterLocation = eventsEnabled.filter((ev) =>
          ev.stores.find((e) => e.city.toUpperCase() === event.value)
        );
      } else {
        filterLocation = eventsEnabled.filter((ev) =>
          ev.stores.find((e) => e.city.toUpperCase() === event.value)
        );
        if (selectedCategory !== "") {
          filterLocation = filterLocation.filter(
            (ev) => ev.type === selectedCategory
          );
        }
        if (selectedLocation !== "") {
          filterLocation = filterLocation.filter((ev) =>
            ev.stores.find((e) => e.city.toUpperCase() === selectedLocation)
          );
        }
      }

      //let filterLocation = eventsEnabled.filter((ev) =>
      //  ev.stores.find((e) => e.city.toUpperCase() === event.target.value)
     // );

      setFilteredList(filterLocation);
    }*/
  };
  useEffect(() => {
    const filtered = eventsEnabled.filter((ev) => {
      return (
        (selectedCategory === "" || ev.type === selectedCategory) &&
        (selectedBrand === "" ||
          ev.brands.find((e) => e.id === selectedBrand)) &&
        (selectedLocation === "" ||
          ev.stores.find((e) => e.city.toUpperCase() === selectedLocation))
      );
    });
    //console.log("selectedBrand", selectedBrand);
    //console.log("selectedCategory", selectedCategory);
    //console.log("selectedLocation", selectedLocation);
    setFilteredList(filtered);
  }, [selectedCategory, selectedBrand, selectedLocation, eventsEnabled]);

  const formatBrand = (str) => {
    //if (str[0] === "") str.shift();
    let strFormat = str;
    console.log("str", str[0]);
    if (str[0] === "1" || str[0] === "2") strFormat = str.slice(1);
    return strFormat
      .toLowerCase()
      .replace(/^[a-z]/g, (x) => x.toUpperCase())
      .replace(/-([a-z]|\&)/g, (_, x) => " " + x.toUpperCase())
      .toUpperCase();
  };

  useEffect(() => {
    console.log("eveeentsssssssssssssssss", events);
    let eventsEnable = [];
    let brandsList = [];
    let locationList = [];
    events?.forEach((ev) => {
      if (ev.enabled === true) {
        let dateEvent = ev.timespanstoreevents;
        let brand = ev.brands;
        //let tiendas = ev.stores;
        let isInDate = false;
        dateEvent.map((d) => {
          let finishDate = moment(d.endDate).format("YYYY/MM/DD");
          if (now <= finishDate) {
            isInDate = true;
          }
        });
        if (isInDate) {
          console.log("ev", ev);
          //if (!eventsEnabled.some((e) => e.id === ev.id)) {
          if (!eventsEnable.some((e) => e.id === ev.id)) {
            console.log("ev22222", ev);
            //setEventsEnabled([...eventsEnabled, ev]);
            eventsEnable.push(ev);
          }
          brand.map((b) => {
            let brandObject = { value: b.id, label: formatBrand(b.name) };
            if (!brandsList.some((e) => e.value === brandObject.value)) {
              brandsList.push({ value: b.id, label: formatBrand(b.name) });
              //brandsList = (...brandsList, {value: b.id, label: b.name });
              /*setBrands([
                ...brands,
                { value: b.id, label: b.name.toUpperCase() },
              ]);*/
            }
          });
          /*tiendas.map((t) => {
            let tienda = { id: t.id, city: t.city };
            if (!locationList.includes(tienda.id)) {
              locationList.push(tienda);
            }
          });*/
        }
      }
      /*sorting(brandsList);
      brandsList.unshift({ value: "", label: "MARCAS" });
      setBrands(brandsList);*/

      // setLocation(locationList);
      setEventsEnabled(eventsEnable);
    });
    sorting(brandsList);
    brandsList.unshift({ value: "", label: "TODAS LAS MARCAS" });
    //brandsList.unshift({ value: "", label: "MARCAS" });

    setBrands(brandsList);
  }, [events]);

  const sorting = (items) => {
    items.sort((a, b) => {
      const nameA = a.label; // ignore upper and lowercase
      const nameB = b.label; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };
  //console.log("locations", location);
  useEffect(() => {
    console.log("eventsEnabled", eventsEnabled);
    setFilteredList(eventsEnabled);
    /*events?.map((event) => {
      let brand = event.brands;
      brand.map((b) => {
        if (!brands.includes(b.name)) {
          setBrands([...brands, b.name]);
        }
      });
      let store = event.stores;*/

    /*store.map((s) => {
        if (!locations.includes(s.city)) {
          setBrands([...locations, s.city]);
        }
      });*/
    //});
  }, [eventsEnabled]);

  //console.log("brands", brands);

  /*useEffect(() => {
    const filteredData = filterByBrand(events);
    setFilteredList(filteredData);
  }, [selectedBrand]);*/

  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;
  //const classes = useStyles();

  const customStylesMobile = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "grey",
      color: state.isFocused ? "black" : "grey",
      padding: 10,
      fontSize: 10,
      background: "#ebddd2",
    }),
    control: () => ({
      width: 100,
      height: 30,
      backgroundColor: "black",
      color: "white",
      border: "none",
      borderRadius: 40,
      zIndex: 2,
      position: "inherit",
    }),
    menu: (provided) => ({
      ...provided,
      width: 150,
      marginTop: 1,
      left: -20,
      color: "black",
      paddingTop: 20,
      paddingBottom: 10,
      paddingLeft: 10,

      borderRadius: "0 0 30px 30px",
      background: "rgb(235,221,210)",
      zIndex: 1,
      height: 230,
      overflowY: "auto",
      // scrollbarWidth: "none",
    }),
    placeholder: () => ({
      color: "white",
      paddingTop: 10,
      textAlign: "center",
      fontSize: 10,
    }),
    singleValue: () => ({
      color: "white",
      paddingTop: 10,
      textAlign: "center",
      fontSize: 10,
      zIndex: 2,
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    valueContainer: () => ({
      height: 60,
      zIndex: 2,
      position: "inherit",
    }),
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "grey",
      color: state.isFocused ? "black" : "grey",
      padding: 10,
      fontSize: 14,
      background: "#ebddd2",
    }),
    control: () => ({
      width: 330,
      backgroundColor: "black",
      color: "white",
      border: "none",
      borderRadius: 40,
      zIndex: 2,
      position: "inherit",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 330,
      marginTop: -30,

      color: "black",
      paddingTop: 60,
      paddingBottom: 40,
      paddingLeft: 30,
      paddingRight: 20,
      borderRadius: "0 0 30px 30px",
      background: "rgb(235,221,210)",
      zIndex: 1,
      //height: 300,
      //overflowY: "auto",
      //scrollbarWidth: "none",
    }),
    placeholder: () => ({
      color: "white",
      paddingTop: 20,
      paddingLeft: 30,
    }),
    singleValue: () => ({
      color: "white",
      paddingTop: 20,
      paddingLeft: 30,
      zIndex: 2,
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    valueContainer: () => ({
      height: 60,
      zIndex: 2,
      position: "inherit",
    }),
  };

  const openFilters = () => {
    filterPressed = !filterPressed;
  };

  return (
    <div>
      <div>
        <div style={{ position: "fixed", zIndex: 999999 }}>
          <GoBackFabBtn />
        </div>
        <Header isHome={false} />
        {/*<Slides featured={featured} />*/}
        <div className="containerEvents">
          <div className="containerTitleSearch">
            <h1 className="tittleEvent">
              EVENTOS
              <p style={{ color: "red", display: "inline" }}> ESPECIALES</p>
            </h1>
          </div>
          <div className="filterEvents">
            <Select
              options={categories}
              styles={isMobile ? customStylesMobile : customStyles}
              defaultValue=""
              placeholder="CATEGORIAS"
              isSearchable={false}
              onChange={handleCategoryChange}
            />
            <Select
              options={brands}
              styles={isMobile ? customStylesMobile : customStyles}
              //defaultValue={brands[0]}
              defaultValue=""
              placeholder="MARCAS"
              isSearchable={false}
              onChange={handleBrandChange}
            />
            <Select
              options={locations}
              styles={isMobile ? customStylesMobile : customStyles}
              //defaultValue={locations[0]}
              defaultValue=""
              placeholder="LOCALIDADES"
              isSearchable={false}
              onChange={handleLocationChange}
            />

            {/* <div class="custom-select">
              <select
                className="selectEvents"
                value={selectedCategory}
                onChange={handleCategoryChange}
                defaultValue=""
              >
                <option value="">CATEGORIA</option>
                {categories.map((category) => {
                  return (
                    <option value={category.value}>{category.desc}</option>
                  );
                })}
              </select>
            </div>
            <div>
              <select
                className="selectEvents"
                value={selectedBrand}
                onChange={handleBrandChange}
                defaultValue=""
              >
                <option value="">MARCAS</option>
                {brands.map((bran) => {
                  return (
                    <option value={bran.id}>{bran.name.toUpperCase()}</option>
                  );
                })}
              </select>
            </div>
            <div>
              <select
                className="selectEvents"
                value={selectedLocation}
                onChange={handleLocationChange}
                defaultValue=""
              >
                <option value="">LOCALIDAD</option>
                {locations.map((location) => {
                  return <option value={location}>{location}</option>;
                })}
              </select>
            </div>*/}
          </div>

          {filteredList.length == 0 ? (
            isMobile ? (
              <img className="img-noEvents" src={noEventsM} />
            ) : (
              <img className="img-noEvents" src={noEvents} />
            )
          ) : (
            <div className="conteinerMain">
              <div className="containerCard">
                {filteredList?.map((eventFilter) => (
                  <EventCards event={eventFilter} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Events };
