import React from "react";
//import header_img from "../../assets/Header/logo.png";
import header_imgWhite from "../../assets/Header/logo_white.png";
import { Link } from "react-router-dom";
import "./styles.css";
//import { Home } from "../../layout/Home";
import calendarimg from "../../assets/Header/icono-calendario.png";

const Header = (isHome) => {
  return (
    <nav>
      <Link to="/">
        <img
          className="img_header_calendar"
          src={calendarimg}
          alt="Juleriaque logo"
          style={{ color: "white" }}
        />
      </Link>

      <Link to={{ pathname: "https://www.juleriaque.com.ar/" }} target="_blank">
        <img
          className="img_header"
          src={header_imgWhite}
          alt="Juleriaque logo"
        />
      </Link>
    </nav>
  );
};

export { Header };
