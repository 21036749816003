import React, { useEffect, useState } from "react";
import moment from "moment";
// import { range } from "moment-range";
//import { daysConsultant, consultDays } from '../../utils'
import "./calendar.css";
import "moment/locale/es"

moment.locale("es")
const CalendarConsult = ({ setSelectDay, selectedDay, timeConsultant, setDateBooking, dataCalendar, selectConsultant, daysEventStore }) => {
  // console.log('====Object.keys(dataCalendar)====', Object.keys(dataCalendar))

  let weekdayshort = moment.weekdaysShort();

  const [showMonthTable, setShowMonthTable] = useState(false);
  const [showDateTable, setShowDateTable] = useState(true);
  const [dateObject, setDateObject] = useState(moment());
  const [allmonths, setAllmonths] = useState(moment.months());

  const [daySelect, setDaySelect] = useState(null);
  const [dataConsult, setDataConsult] = useState(null);
  // const [selectedDay, setSelectedDay] = useState(null);
  const [days, setDays] = useState(0);
  const today = moment().subtract(3, "hours").format("YYYY/MM/DD");

  useEffect(() => {
    if (dataCalendar && selectConsultant) {
      setDataConsult(dataCalendar[selectConsultant.id])
    }

    console.log("dataCalendar", dataCalendar);
  }, [dataCalendar, selectConsultant])


  const daysInMonth = () => {
    //let _dateObject = moment();
    let _dateObject = moment(dateObject._d);
    // console.log('dateObject.daysInMonth()', dateObject.daysInMonth())
    return _dateObject.daysInMonth(); // dias del mes Number
  };
  /*const year = () => {
    return dateObject.format("Y");
  };
  const currentDay = () => {
    return dateObject.format("D");
  };*/
  const firstDayOfMonth = () => {
    //let _dateObject = moment();
    let _dateObject = moment(dateObject._d);
    let firstDay = moment(_dateObject)
      .startOf("month")
      .format("d"); // Day of week 0...1..5...6
    return firstDay;
  };
  const month = () => {
    //let _dateObject = moment()
    let _dateObject = moment(dateObject._d)
    return _dateObject.format("MMMM").toUpperCase();
  };
  /*const showMonth = (e, month) => {
    setShowMonthTable(!showMonthTable)
    setShowDateTable(!showDateTable)
    // this.setState({
    //   showMonthTable: !this.state.showMonthTable,
    //   showDateTable: !this.state.showDateTable
    // });
  };*/
  /*const setMonth = (month) => {
    let monthNo = allmonths.indexOf(month);
    let _dateObject = Object.assign({}, dateObject);
    _dateObject = moment(dateObject).set("month", monthNo);
    setDateObject(_dateObject)
    setShowMonthTable(!showMonthTable)
    setShowDateTable(!showDateTable)
    // this.setState({
    //   dateObject: dateObject,
    //   showMonthTable: !this.state.showMonthTable,
    //   showDateTable: !this.state.showDateTable
    // });
  };*/

  const onPrev = () => {
    let curr = "month";
    /*if (showYearTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }*/

    setDateObject(dateObject.subtract(1, curr));
    setDays(days - 1)
  };
  const onNext = () => {
    let curr = "month";
    /*if (showYearTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }*/

    setDateObject(dateObject.add(1, curr));
    setDays(days + 1)
  };

  const onDayClick = (e, d, date) => {
    let dateSelect = (dateObject.date(d)).format('YYYY/MM/DD')
    setDaySelect(d)
    let format_date = new Date(date)
    setDateBooking(format_date)
    // let isDay = (moment().date(d)).format('dddd')
    // let nameDay = isDay.charAt(0).toUpperCase() + isDay.slice(1)

    // let day = consultDays[nameDay]

    // let workDay = timeConsultant.find((time) => {
    //   console.log('time', time)
    //   return time.day.toUpperCase() === day.toUpperCase()
    // })
    setSelectDay(dateSelect)
  };

  /*const stackDays = timeConsultant?.map((workDay) => {
    // console.log('workDay', workDay)
    return daysConsultant[workDay.day].toUpperCase()
  })*/

  const classDay = (d, date) => {
    // let storeWithEvent = daysEventStore.includes(date)
    //let isDay = Object.keys(dataConsult).includes(d.toString())
    let isDay = Object.keys(dataConsult).includes(date)

    //console.log('isDayyyyy', date);
    //console.log('todayyyyyy', today);

    //if (storeWithEvent) return 'calendar-day-event-store'
    /*else*/ if (isDay && date >= today) return 'calendar-day-eventin'
    else return 'calendar-day-eventout'
  }

  const createCalendar = () => {
    let weekdayshortname = weekdayshort.map(day => {
      return <th key={day} className="calendar-weekday">{day.toUpperCase()}</th>;
    });
    let blanks = [];
    for (let i = 0; i < firstDayOfMonth(); i++) {
      blanks.push(<td key={i} className="calendar-day empty">{""}</td>);
    }
    let _daysInMonth = [];
    for (let d = 1; d <= daysInMonth(); d++) {
      //let date = (moment().date(d)).format('YYYY/MM/DD')
      let date = (dateObject.date(d)).format('YYYY/MM/DD')
      let isDay = Object.keys(dataConsult).includes(date)
      // let _className = classDay(d)
      let _className;
      if (isDay && daySelect === d) {
        _className = 'calendar-day-select'
        //_classNamePopup = 'popupoff'
      } else _className = classDay(d, date)
      _daysInMonth.push(
        <td key={`${d}c`} onClick={(e) => isDay ? onDayClick(e, d, date) : null} className={_className}>
          {(_className === 'calendar-day-event-store') &&
            <span className='popup-event-store'> Día de Evento en el Local Seleccionado. Participe del mismo ingresando a "Otros Eventos" </span>
          }

          <span>{d}</span>
        </td>
      );
    }
    var totalSlots = [...blanks, ..._daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      // console.log('d', d)
      return <tr key={i}>{d}</tr>;
    });

    return (
      <table className="calendar-day">
        <thead>
          <tr>{weekdayshortname}</tr>
        </thead>
        <tbody>{daysinmonth}</tbody>
      </table>
    )
  }


  return (
    <div className="tail-datetime-calendar">
      <div className="calendar-navi">

        <span
          onClick={e => {
            onPrev();
          }}
          className="calendar-button button-prev"
        > &#60; </span>
        {!showMonthTable && (
          <span
            // onClick={e => {
            //   showMonth();
            // }}
            className="calendar-label"
          >
            {month()}
          </span>
        )}
        <span
          onClick={e => {
            onNext();
          }}
          className="calendar-button button-next"
        > &#62; </span>
      </div>

      {showDateTable && dataConsult && (
        <div className="calendar-date">
          {createCalendar()}
        </div>
      )}
    </div>
  );
}

export { CalendarConsult }
