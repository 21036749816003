import React, { useState } from "react";
import moment from "moment";
// import { range } from "moment-range";
import "./calendar.css";
import "moment/locale/es"

moment.locale("es")
const EmptyCalendar = () => {

  let weekdayshort = moment.weekdaysShort();
  // moment.locale('es')

  const [showYearTable, setShowYearTable] = useState(false);
  const [showMonthTable, setShowMonthTable] = useState(false);
  const [showDateTable, setShowDateTable] = useState(true);
  const [dateObject, setDateObject] = useState(moment());
  const [allmonths, setAllmonths] = useState(moment.months());
  // const [selectedDay, setSelectedDay] = useState(null);

  // useEffect(() => {
  //   console.log('selectedDay', selectedDay)
  //   // console.log('setTimeCalendar', setTimeCalendar())
  //   createCalendar()
  // }, [selectedDay])


  const daysInMonth = () => {
    let _dateObject = moment();
    // console.log('dateObject.daysInMonth()', dateObject.daysInMonth())
    return _dateObject.daysInMonth(); // dias del mes Number
  };
  /*const year = () => {
    return dateObject.format("Y");
  };
  const currentDay = () => {
    return dateObject.format("D");
  };*/
  const firstDayOfMonth = () => {
    let _dateObject = moment();

    let firstDay = moment(_dateObject)
      .startOf("month")
      .format("d"); // Day of week 0...1..5...6
    return firstDay;
  };
  const month = () => {
    let _dateObject = moment()
    return _dateObject.format("MMMM").toUpperCase();
  };
  /*const showMonth = (e, month) => {
    setShowMonthTable(!showMonthTable)
    setShowDateTable(!showDateTable)
    // this.setState({
    //   showMonthTable: !this.state.showMonthTable,
    //   showDateTable: !this.state.showDateTable
    // });
  };*/
  /*const setMonth = (month) => {
    let monthNo = allmonths.indexOf(month);
    let _dateObject = Object.assign({}, dateObject);
    _dateObject = moment(dateObject).set("month", monthNo);
    setDateObject(_dateObject)
    setShowMonthTable(!showMonthTable)
    setShowDateTable(!showDateTable)
    // this.setState({
    //   dateObject: dateObject,
    //   showMonthTable: !this.state.showMonthTable,
    //   showDateTable: !this.state.showDateTable
    // });
  };*/

  // MonthList = props => {
  //   let months = [];
  //   props.data.map(data => {
  //     months.push(
  //       <td
  //         key={data}
  //         className="calendar-month"
  //         onClick={e => {
  //           this.setMonth(data);
  //         }}
  //       >
  //         <span>{data}</span>
  //       </td>
  //     );
  //   });
  //   let rows = [];
  //   let cells = [];

  //   months.forEach((row, i) => {
  //     if (i % 3 !== 0 || i == 0) {
  //       cells.push(row);
  //     } else {
  //       rows.push(cells);
  //       cells = [];
  //       cells.push(row);
  //     }
  //   });
  //   rows.push(cells);
  //   let monthlist = rows.map((d, i) => {
  //     return <tr>{d}</tr>;
  //   });

  //   return (
  //     <table className="calendar-month">
  //       <thead>
  //         <tr>
  //           <th colSpan="4">Select a Month</th>
  //         </tr>
  //       </thead>
  //       <tbody>{monthlist}</tbody>
  //     </table>
  //   );
  // };
  // showYearTable = e => {
  //   this.setState({
  //     showYearTable: !this.state.showYearTable,
  //     showDateTable: !this.state.showDateTable
  //   });
  // };

  const onPrev = () => {
    let curr = "";
    if (showYearTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    setDateObject(dateObject.subtract(1, curr));
  };
  const onNext = () => {
    let curr = "";
    if (showYearTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }

    setDateObject(dateObject.add(1, curr));
  };
  // setYear = year => {
  //   // alert(year)
  //   let dateObject = Object.assign({}, this.state.dateObject);
  //   dateObject = moment(dateObject).set("year", year);
  //   this.setState({
  //     dateObject: dateObject,
  //     showMonthTable: !this.state.showMonthTable,
  //     showYearTable: !this.state.showYearTable
  //   });
  // };
  // onYearChange = e => {
  //   this.setYear(e.target.value);
  // };
  // const getDates = (startDate, stopDate) => {
  //   var dateArray = [];
  //   var currentDate = moment(startDate);
  //   var stopDate = moment(stopDate);
  //   while (currentDate <= stopDate) {
  //     dateArray.push(moment(currentDate).format("YYYY"));
  //     currentDate = moment(currentDate).add(1, "year");
  //   }
  //   return dateArray;
  // }
  // YearTable = props => {
  //   let months = [];
  //   let nextten = moment()
  //     .set("year", props)
  //     .add("year", 12)
  //     .format("Y");

  //   let tenyear = this.getDates(props, nextten);

  //   tenyear.map(data => {
  //     months.push(
  //       <td
  //         key={data}
  //         className="calendar-month"
  //         onClick={e => {
  //           this.setYear(data);
  //         }}
  //       >
  //         <span>{data}</span>
  //       </td>
  //     );
  //   });
  //   let rows = [];
  //   let cells = [];

  //   months.forEach((row, i) => {
  //     if (i % 3 !== 0 || i == 0) {
  //       cells.push(row);
  //     } else {
  //       rows.push(cells);
  //       cells = [];
  //       cells.push(row);
  //     }
  //   });
  //   rows.push(cells);
  //   let yearlist = rows.map((d, i) => {
  //     return <tr>{d}</tr>;
  //   });

  //   return (
  //     <table className="calendar-month">
  //       <thead>
  //         <tr>
  //           <th colSpan="4">Select a Yeah</th>
  //         </tr>
  //       </thead>
  //       <tbody>{yearlist}</tbody>
  //     </table>
  //   );
  // };


  const createCalendar = () => {
    let weekdayshortname = weekdayshort.map((day, i) => {
      return <th key={i} className="calendar-weekday">{day.toUpperCase()}</th>;
    });
    let blanks = [];
    for (let i = 0; i < firstDayOfMonth(); i++) {
      blanks.push(<td key={i} className="calendar-day empty">{""}</td>);
    }
    let _daysInMonth = [];
    for (let d = 1; d <= daysInMonth(); d++) {

      _daysInMonth.push(
        <td key={`${d}c`} className="calendar-day">
          <span>{d}</span>
        </td>
      );
    }
    var totalSlots = [...blanks, ..._daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      // console.log('d', d)
      return <tr key={i}>{d}</tr>;
    });

    return (
      <table className="calendar-day">
        <thead>
          <tr>{weekdayshortname}</tr>
        </thead>
        <tbody>{daysinmonth}</tbody>
      </table>
    )
  }


  return (
    <div className="tail-datetime-calendar">
      <div className="calendar-navi">
        <span
          onClick={e => {
            onPrev();
          }}
          className="calendar-button button-prev"
        />
        {!showMonthTable && (
          <span
            // onClick={e => {
            //   showMonth();
            // }}
            className="calendar-label"
          >
            {month()}
          </span>
        )}
        {/* <span className="calendar-label" onClick={e => this.showYearTable()}>
          {this.year()}
        </span> */}
        <span
          onClick={e => {
            onNext();
          }}
          className="calendar-button button-next"
        />
      </div>

      {/* <div className="calendar-date">
          {this.state.showYearTable && <this.YearTable props={this.year()} />}
          {this.state.showMonthTable && (
            <this.MonthList data={moment.months()} />
          )}
        </div> */}

      {showDateTable && (
        <div className="calendar-date">
          {createCalendar()}
        </div>
      )}
    </div>
  );
}

export { EmptyCalendar }
