export const GET_ALL_EVENTS = 'GET_ALL_EVENTS'
export const GET_ALL_STORES_WHIT_CONSULTANT = 'GET_ALL_STORES_WHIT_CONSULTANT'
export const GET_CARROUSEL_EVENTS = 'GET_CARROUSEL_EVENTS'
export const SEND_DATA_BOOKING_CONSUTANT = 'SEND_DATA_BOOKING_CONSUTANT'
export const GET_DAYS_EVENTS_STORE = 'GET_DAYS_EVENTS_STORE'
export const GET_WORKING_DAYS = 'GET_WORKING_DAYS'
export const GET_RESTING_DAYS = 'GET_RESTING_DAYS'
export const GET_SINGLE_EVENT = 'GET_SINGLE_EVENT'
export const RESET_EVENT = 'RESET_EVENT'
export const SEND_DATA_BOOKING = 'SEND_DATA_BOOKING'
export const DELETE_BOOKING_EVENT = "DELETE_BOOKING_EVENT";
export const RESPONSE_DELETE_BOOKING_EVENT = "RESPONSE_DELETE_BOOKING_EVENT";
export const DELETE_BOOKING_CONSULTANT = "DELETE_BOOKING_CONSULTANT";
export const RESPONSE_DELETE_BOOKING_CONSULTANT = "RESPONSE_DELETE_BOOKING_CONSULTANT";
export const GET_BOOKINGS_EVENTS = "GET_BOOKINGS_EVENTS";
export const GET_BOOKINGS_CONSULTANTS = "GET_BOOKINGS_CONSULTANTS";

export const _TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYXJrZXRpbmdfaWQiOjMsImlhdCI6MTYyNDczOTAwM30.ZiQP2FzzmDu6UcbOJghxskvmxP0_s8rv3BaSo8iBpt8'
