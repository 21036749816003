import {
  GET_ALL_EVENTS, GET_SINGLE_EVENT, _TOKEN, RESET_EVENT, SEND_DATA_BOOKING, DELETE_BOOKING_EVENT,
  RESPONSE_DELETE_BOOKING_EVENT, GET_BOOKINGS_EVENTS
} from '../../../constants'
import swal from "sweetalert";

export const getAllEvents = () => ({
  type: GET_ALL_EVENTS,
  payload: async () => {
    const res = await fetch("/events", {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })

    const data = await res.json();
    console.log('datorovich', data)
    if (res.ok) return data;
    else throw data;
  }
});
export const getBookingsEvents = (id) => ({
  type: GET_BOOKINGS_EVENTS,
  payload: async () => {
    const res = await fetch(`/get-bookings-event/${id}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })

    const data = await res.json();
    console.log('datosbookinggggggg', data)
    if (res.ok) return data;
    else throw data;
  }
});

export const getEvent = (id) => ({
  type: GET_SINGLE_EVENT,
  payload: async () => {
    const res = await fetch(`/events/${id}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })

    const data = await res.json();
    console.log('dataaaaaaaaaa', data)
    if (res.ok) return data;
    else return null;
  }
});
export const setReset = () => ({
  type: RESET_EVENT,
  payload: {}
});

export const sendBooking = (dataBooking) => ({
  type: SEND_DATA_BOOKING,
  payload: async () => {
    console.log('bookingACtion------------', dataBooking)
    const response = await fetch('/reserva-tu-lugar', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(dataBooking) // body data type must match "Content-Type" header
    });
    console.log('responseeeeeeeeeeeeeeeeeeeeeee', response);
    if (response.ok) {
      // let history = useHistory();
      swal("Tu reserva se guardo con exito! \n \n Si no recibís el mail, recordá revisar correo No Deseado")
        .then((value) => {
          window.location.href = "/";
        });
      return response.json(); // parses JSON response into native JavaScript objects
    } else {
      if (response.status === 450) {
        swal('Lo sentimos, el turno que intentaste reservar ya no está disponible. Por favor, selecciona otro turno.')
          .then((value) => {
            window.location.reload();
          });
      } else {
        swal("Error interno, porfavor intente de nuevo.")
          .then((value) => {
            window.location.href = "/";
          });
      }



      return response.json(); // parses JSON response into native JavaScript objects
    }
  }
});

export const deleteBooking = (id) => ({
  type: DELETE_BOOKING_EVENT,
  payload: async () => {
    const res = await fetch(`/delete-booking-event/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + _TOKEN,
        },
      }
    );

    const data = await res.json();
    console.log("deletedatadeletedata", data);
    if (res.ok) return data;
    else return null;
  },
});

export const emailDeleteBookingEvent = (id, data) => ({
  type: RESPONSE_DELETE_BOOKING_EVENT,
  payload: async () => {
    console.log("responseDeleteBooking------------", data);
    const response = await fetch(`/delete-booking-event/${id}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    );
    if (response.ok) {
      // let history = useHistory();
      swal("Tu reserva se canceló con exito!").then((value) => {
        window.location.href = "/confirm-delete-booking-event";
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  },
});

