import { GET_ALL_STORES_WHIT_CONSULTANT, GET_WORKING_DAYS, GET_RESTING_DAYS, SEND_DATA_BOOKING_CONSUTANT, GET_DAYS_EVENTS_STORE, RESPONSE_DELETE_BOOKING_CONSULTANT, GET_BOOKINGS_CONSULTANTS } from '../../../constants'

const INITIAL_STATE = {
  didInvalidate: false,
  isFetching: false,
  featured: null,
  stores: null,
  zones: null,
  workingDays: null,
  restingDays: null,
  bookingConsultant: null,
  dataCalendar: null,
  daysEventStore: null,
  deleteBookingConsultant: null
}

const consultantReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case `${GET_ALL_STORES_WHIT_CONSULTANT}_FULFILLED`: {
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        stores: payload.stores,
        featured: payload.featured,
        zones: payload.zones,
        consultants: payload.cosultants
      }
    }
    case `${GET_ALL_STORES_WHIT_CONSULTANT}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      }
    }
    case `${GET_ALL_STORES_WHIT_CONSULTANT}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    }
    case `${GET_WORKING_DAYS}_FULFILLED`: {
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        workingDays: payload.data.workingDay,
        bookingConsultant: payload.data.bookingConsultant,
        dataCalendar: payload.limitData
      }
    }
    case `${GET_WORKING_DAYS}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      }
    }
    case `${GET_WORKING_DAYS}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    }
    case `${GET_RESTING_DAYS}_FULFILLED`: {
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        restingDays: payload.data.restingDays,
        bookingConsultant: payload.data.bookingConsultant,
        dataCalendar: payload.limitData
      }
    }
    case `${GET_RESTING_DAYS}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      }
    }
    case `${GET_RESTING_DAYS}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    }
    case `${SEND_DATA_BOOKING_CONSUTANT}_FULFILLED`: {
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
      }
    }
    case `${SEND_DATA_BOOKING_CONSUTANT}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      }
    }
    case `${SEND_DATA_BOOKING_CONSUTANT}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    }
    case `${GET_DAYS_EVENTS_STORE}_FULFILLED`: {
      return {
        ...state,
        didInvalidate: false,
        isFetching: false,
        daysEventStore: payload.eventsDayStore,
      }
    }
    case `${GET_DAYS_EVENTS_STORE}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      }
    }
    case `${GET_DAYS_EVENTS_STORE}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    }
    case `${RESPONSE_DELETE_BOOKING_CONSULTANT}_FULFILLED`: {
      return {
        ...state,
        deleteBookingConsultant: payload.deleteBookingConsultant,
        didInvalidate: false,
        isFetching: false,
      };
    }
    case `${RESPONSE_DELETE_BOOKING_CONSULTANT}_REJECTED`: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      };
    }
    case `${RESPONSE_DELETE_BOOKING_CONSULTANT}_PENDING`: {
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    }

    default:
      return state;
  }
};

export { consultantReducer }