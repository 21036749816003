import React from "react";
import { useHistory } from "react-router-dom";
//import moment from "moment";

import "./styles.css";

export default function EventCards({ event }) {
  //const [eventPast, setEventPast] = useState(false);z
  //const now = moment().format("YYYY/MM/DD");
  let history = useHistory();
  const handleSingleEvent = (id) => {
    history.push(`/evento/${id}`);
  };
  // let urlimg = `/uploads/events/${event.imageBox}`;
  return (
    <div
      key={event.id}
      className="card"
      onClick={() => handleSingleEvent(event.id)}
    >
      <div className="leftSideRows">
        <div className="leftStyle">
          <img
            src={"/uploads/events/" + event.imageBox}
            className="imageEvent"
            alt=""
          />
        </div>
      </div>
      <div className="rightSideRows">
        <div className="containerBrands">
          {event?.brands.map((brand) => {
            return (
              <div className="upSide" key={brand.id}>
                <img
                  className="imageBrand"
                  src={"/uploads/brands/" + brand.image}
                  alt={brand.image + " logo"}
                />
              </div>
            );
          })}

          {/*<img
                      className="imageBrand"
                      src="{http://assets.stickpng.com/thumbs/584294a4a6515b1e0ad75ace.png}"
                     />*/}
        </div>
        <div>
          <hr />
        </div>
        <div className="downSide">
          <div className="tittle">{event.name} </div>
          <div className="description"> {event.description}</div>

          <button
            onClick={() => handleSingleEvent(event.id)}
            className="button"
          >
            RESERVA TU LUGAR
          </button>
        </div>
      </div>
    </div>
  );
}

export { EventCards };
