import React, { useRef } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ButtonBase from "@material-ui/core/ButtonBase";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import imageDefault from "../../assets/imagedefault.jpg";

const Slides = ({ featured }) => {
  console.log("featured1111", featured);
  //const [, , ...featureds] = Object.values(featured).reverse();
  //const  featuredArray[] =

  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;

  //console.log("featured222", featureds);
  const slide = useRef();
  let history = useHistory();
  const handleSingleEvent = (id) => {
    history.push(`/evento/${id}`);
  };
  const renderArrows = () => {
    return (
      <div className="slider-arrow">
        <ButtonBase
          className="arrow-btn prev"
          onClick={() => slide.current.slickPrev()}
        >
          <ArrowLeft />
        </ButtonBase>
        <ButtonBase
          className="arrow-btn next"
          onClick={() => slide.current.slickNext()}
        >
          <ArrowRight />
        </ButtonBase>
      </div>
    );
  };

  return (
    <div className="constainer-slides">
      <div style={{ position: "relative" }}>
        {renderArrows()}
        <Slider
          ref={slide}
          infinite={true}
          speed={1500}
          autoplay={true}
          autoplaySpeed={3500}
          dots={true}
          dotsClass={"button__bar"}
          arrows={false}
          // centerMode={true}
          slidesToShow={1}
        >
          {featured.length > 0 ? (
            featured.map((el, index) => (
              <div key={index} className="divSlide">
                {el.imageEventBanner ? (
                  <div onClick={() => handleSingleEvent(el.positionId)}>
                    {!isMobile ? (
                      <img
                        className="slide"
                        src={`/uploads/events/${el.imageEventBanner}`}
                        alt="banner1"
                      />
                    ) : (
                      <img
                        className="slide"
                        src={`/uploads/events/${el.imageEventBannerMobile}`}
                        alt="banner1"
                      />
                    )}
                  </div>
                ) : el.isCita === 2 ? (
                  <div onClick={() => history.push(`/reservar-por-consultor`)}>
                    {!isMobile ? (
                      <img
                        className="slide"
                        src={`/uploads/carrousels/${el.positionUpload}`}
                        alt="banner1"
                      />
                    ) : (
                      <img
                        className="slide"
                        src={`/uploads/carrousels/${el.positionUploadMobile}`}
                        alt="banner1"
                      />
                    )}
                  </div>
                ) : (
                  el.isCita === 1 && (
                    <div onClick={() => history.push(`/eventos`)}>
                      {!isMobile ? (
                        <img
                          className="slide"
                          src={`/uploads/carrousels/${el.positionUpload}`}
                          alt="banner1"
                        />
                      ) : (
                        <img
                          className="slide"
                          src={`/uploads/carrousels/${el.positionUploadMobile}`}
                          alt="banner1"
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            ))
          ) : (
            <div className="divSlide">
              <div>
                <img className="slide" src={imageDefault} alt="banner1" />
              </div>
            </div>
          )}

          {/* {typeof featured?.featured1 === "object" ? (
            <div
              key={featured?.featured1.id}
              onClick={() => handleSingleEvent(featured?.featured1.id)}
            >
              <img
                className="slide"
                src={`/uploads/events/${featured?.featured1.imageBanner}`}
                alt="banner1"
              />
            </div>
          ) : (
            (featured?.featured1 != null) ? (
              (featured?.featured1[0] === '-') ? ( 
                <div onClick={() => history.push(`/reservar-por-consultor`)}> 
                  <img
                    className="slide"
                    src={`/uploads/carrousels/${featured?.featured1.slice(1)}`}
                    alt="banner1"
                  />
              </div>
                ) : (
                  <div onClick={() => history.push(`/eventos`)}> 
                  <img
                    className="slide"
                    src={`/uploads/carrousels/${featured?.featured1}`}
                    alt="banner1"
                  />
                </div>
              )
            ) : (
              <div>
              <img
                className="slide"
                src={`/uploads/carrousels/${featured?.featured1}`}
                alt="banner1"
              />
              </div>
            )
            )}
          {typeof featured?.featured2 === "object" ? (
            <div
              key={featured?.featured2.id}
              onClick={() => handleSingleEvent(featured?.featured2.id)}
            >
              <img
                className="slide"
                src={`/uploads/events/${featured?.featured2.imageBanner}`}
                alt="banner2"
              />
            </div>
          ) : (
            (featured?.featured2 != null) ? (
              (featured?.featured2[0] === '-') ? ( 
                <div onClick={() => history.push(`/reservar-por-consultor`)}> 
                  <img
                    className="slide"
                    src={`/uploads/carrousels/${featured?.featured2.slice(1)}`}
                    alt="banner2"
                  />
              </div>
                ) : (
                  <div onClick={() => history.push(`/eventos`)}> 
                  <img
                    className="slide"
                    src={`/uploads/carrousels/${featured?.featured2}`}
                    alt="banner2"
                  />
                </div>
              )
          ) : (
            <div>
            <img
              className="slide"
              src={`/uploads/carrousels/${featured?.featured2}`}
              alt="banner1"
            />
            </div>
          )
          )}
          
          {typeof featured?.featured3 === "object" ? (
            
            <div
              key={featured?.featured3.id}
              onClick={() => handleSingleEvent(featured?.featured3.id)}
            >
              <img
                className="slide"
                src={`/uploads/events/${featured?.featured3.imageBanner}`}
                alt="banner3"
              />
            </div>
          ) : (
            (featured?.featured3 != null) ? (
              (featured?.featured3[0] === '-') ? ( 
              <div onClick={() => history.push(`/reservar-por-consultor`)}> 
                <img
                  className="slide"
                  src={`/uploads/carrousels/${featured?.featured3.slice(1)}`}
                  alt="banner3"
                />
              </div>
              ) : (
                <div onClick={() => history.push(`/eventos`)}> 
                  <img
                    className="slide"
                    src={`/uploads/carrousels/${featured?.featured3}`}
                    alt="banner3"
                  />
                </div>
              )
            ) : (
              <div>
            <img
              className="slide"
              src={`/uploads/carrousels/${featured?.featured3}`}
              alt="banner1"
            />
            </div>
            )  
          )} */}
        </Slider>
      </div>
    </div>
  );
};

export { Slides };
