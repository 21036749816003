import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllEvents, getEvent, sendBooking, setReset, emailDeleteBookingEvent,
  deleteBooking, getBookingsEvents,
} from '../../redux/actions'

const useEvents = () => {

  const { events, featured, singleEvent, bookingsEvent, allBookingsEvent, shortTimeSpan, isFetching } = useSelector(
    (state) => state.eventReducer,
  )
  const dispatch = useDispatch()


  useEffect(() => {
    getEvents()
  }, [])

  const getEvents = useCallback(async () => {
    // if(!events){
    dispatch(getAllEvents())
    // }
  }, [dispatch])

  const getSingleEvent = useCallback(async (id) => {
    // if(!events){
    return dispatch(getEvent(id))
    // }
  }, [dispatch])

  const getBookingsEventsId = useCallback(async (id) => {
    // if(!events){
    return dispatch(getBookingsEvents(id))
    // }
  }, [dispatch])

  const sendDataBooking = useCallback(async (data) => {
    // if(!events){
    dispatch(sendBooking(data))
    // }
  }, [dispatch])

  const setRestart = useCallback(async () => {
    // if(!events){
    dispatch(setReset())
    // }
  }, [dispatch])

  const deleteBookingEventEmail = useCallback(
    async (id, data) => {
      // if(!events){
      dispatch(emailDeleteBookingEvent(id, data));
      // }
    },
    [dispatch]
  );

  const sendDeleteBooking = useCallback(
    async (id) => {
      // if(!events){
      dispatch(deleteBooking(id));
      // }
    },
    [dispatch]
  );






  return {
    setRestart, getEvents, getSingleEvent, events, featured, singleEvent, allBookingsEvent, bookingsEvent, sendDataBooking, shortTimeSpan, isFetching, deleteBookingEventEmail,
    sendDeleteBooking, getBookingsEventsId
  }
}

export { useEvents }